import React from 'react';
import { connect } from 'react-redux'
import Preloader from '../Preloader';
import { getCourseYearBreakdown, getDegreeCourses } from '../../services/courses';

class TranscriptsPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingCourses: true
        }
    }

    async componentDidMount(){
        const courses = await getDegreeCourses(this.props.auth.agent.token, this.props.degree.id);
        const yearBreakDown = getCourseYearBreakdown(courses);
        this.setState({ courses, yearBreakDown, loadingCourses: false })
    }

    render(){
        return (
            
            <div className='transcripts_main_page'>
                {!this.state.loadingCourses ? (
                    <div className='transcript_container'>
                        <div className='transcript_container__content'>
                            <div className='transcript_header'>
                                <div>
                                    <div className='transcript_page__subheading'>{this.props.degree.degree_name}</div>
                                    <div className='transcript_page__heading'>Academic Transcripts</div>
                                </div>
                                <div className='transcript_header__logo'>
                                    <img src={this.props.auth.university.logo} alt='Insitution Logo'/>
                                </div>
                            </div>
                            {this.state.courses.length > 0 ? (
                                this.state.yearBreakDown.map(year => (
                                    <div className='transcript_year'>
                                        <div className='transcript_year_title'>{year.year}</div>
                                        <div className='transcript_year_subtitle'>{year.courses.length + ' course' + (year.courses.length > 1 ? 's' : '')}</div>
                                        <table className='transcript_table'>
                                            <colgroup>
                                                <col/>
                                                <col className='transcript_description'/>
                                                <col className='center'/>
                                                <col className='center'/>
                                                <col/>
                                            </colgroup>  
                                            <thead>
                                                <tr>
                                                    <th>Course</th>
                                                    <th>Description</th>
                                                    <th className='center'>Credits Earned</th>
                                                    <th className='center'>Result</th>
                                                    <th>Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {year.courses.map(course => (
                                                    <tr>
                                                        <td>{course.course_id}</td>
                                                        <td>{course.course_name}</td>
                                                        <td className='center'>{course.credits_earned}</td>
                                                        <td className='center'>{course.final_result}%</td>
                                                        <td>{course.comment}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ))
                            ) : <p className='not_found'>No transcript results found. Transcripts will appear here as they are loaded to your degree.</p>}
                        </div>
                        <div className='transcript_footer'>
                            <div className='transcript_footer__info'>
                                <div className='transcript_footer__info__title'>{this.props.auth.university.name}</div>
                                <div className='transcript_footer__info__subtitle'>{this.props.auth.university.city}, {this.props.auth.university.country}</div>
                            </div>
                            <div><a target='_blank' rel="noreferrer" href={this.props.auth.university.domain}>{this.props.auth.university.domain}</a></div>
                        </div>
                    </div>
                ) : <Preloader fitToContent={true}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(TranscriptsPage);
import React from 'react';
import { connect } from 'react-redux';
import { studentActions } from '../../actions/students';
import { createDegreeIssuanceRequest } from '../../services/entra'
import socket from '../../services/socket';
import Preloader from '../Preloader';
import Countdown from 'react-countdown';
import { timerRenderer } from '../../services/misc';
import moment from 'moment';
import AlertBox from '../AlertBox';

class DegreeIssuanceGenerator extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            loadingIssuance: true,
            error: null,
            callbackInfo: null,
            awaitingCallback: false
        }

        this.handleCreateRequest = this.handleCreateRequest.bind(this);
        this.handleCodeRefresh = this.handleCodeRefresh.bind(this);
    }

    async componentDidMount(){
        socket.on('presentationRequestRetrieved', ({ state }) => {
            if(this.state.issuanceRequest && this.state.issuanceRequest.state === state){
                this.setState({ awaitingCallback: true });
            }
        });

        socket.on('issuanceRequestSuccessful', async (body) => {
            if(this.state.issuanceRequest && this.state.issuanceRequest.state === body.state){
                await this.props.onSuccessfulIssuance();
                this.setState({ error: null })
                const { dispatch } = this.props;
                dispatch(studentActions.loginStudentWithPayload(body));
            }
        });

        this.handleCreateRequest();
    }

    async handleCreateRequest(){
        this.setState({ loadingIssuance: true })

        try{
            const issuanceRequest = await createDegreeIssuanceRequest(this.props.token);
            this.setState({
                issuanceRequest,
                error: null,
                loadingIssuance: false,
                timeRemaining: moment.unix(issuanceRequest.expiry)
            });

            const interval = setInterval(() => {
                if(moment().isSameOrAfter(this.state.timeRemaining)){
                    clearInterval(this.state.currentInterval);
                    this.handleCreateRequest();
                }
            }, 1000);

            this.setState({ currentInterval: interval });
        }
        catch(e){
            this.setState({ error: e })
        }
    }

    handleCodeRefresh(){
        clearInterval(this.state.currentInterval);
        this.setState({ awaitingCallback: false }, () => {
            this.handleCreateRequest();
        });
    }

    componentWillUnmount(){
        socket.off('presentationRequestRetrieved');
        socket.off('issuanceRequestSuccessful');
    }

    render(){
        return !this.state.loadingIssuance ? (!this.state.awaitingCallback ? (
            <div className='qr_form'>
                <p>{this.props.text}</p>
                <img className='login_code' src={this.state.issuanceRequest.qrCode}/>
                {this.state.timeRemaining && <div className='expiry_countdown mt-medium'>Expires In: <Countdown date={moment(this.state.timeRemaining)} renderer={timerRenderer}/></div>}
                {this.state.error && <AlertBox type="alert alert-danger mt-medium">{this.state.error}</AlertBox>}
            </div>
        ) : (
            <div className='waiting_for_auth'>
                <Preloader/>
                <div className='waiting_for_auth__label'>Please authenticate the request using your authenticator app.</div>
                <div className='regen_code_button' onClick={this.handleCodeRefresh}>Refresh</div>
                {this.state.timeRemaining && <div className='expiry_countdown mt-medium'>Expires In: <Countdown date={moment(this.state.timeRemaining)} renderer={timerRenderer}/></div>}
                {this.state.error && <AlertBox type="alert alert-danger mt-medium">{this.state.error}</AlertBox>}
            </div>
        )) : (
            <Preloader/>
        );
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(DegreeIssuanceGenerator);


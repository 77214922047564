import React from 'react';
import { connect } from 'react-redux';
import { getShare, verifyPin } from '../../services/degrees';
import { Redirect } from 'react-router-dom';
import Preloader from '../Preloader';
import DegreeIssuanceGenerator from './DegreeIssuanceGenerator';
import EmployerVerifiyAndLink from './EmployerVerifiyAndLink';
import ReactCodeInput from 'react-verification-code-input';
import { HiArrowCircleRight, HiOutlineCheck } from 'react-icons/hi';
import { history } from '../../helpers/history';

class EmployerGeneratePage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingShareRecord: true,
            error: null,
            share: null,
            pinVerified: false,
            enteredPin: ''
        }

        this.handlePinChange = this.handlePinChange.bind(this);
        this.handlePinSubmit = this.handlePinSubmit.bind(this);
        this.handleSuccessfulIssuance = this.handleSuccessfulIssuance.bind(this);
        this.handleVerifyAndLink = this.handleVerifyAndLink.bind(this);
    }

    async componentDidMount(){
        const token = this.props.match.params.token;
        try{
            const share = await getShare(token);
            if(!share.employerEntraEnabled){ return history.push('/login'); }
            this.setState({ share, loadingShareRecord: false })    
        }catch(e){
            this.setState({ error: e.message, loadingShareRecord: false, enteredPin: '' })
        }
    }

    handlePinChange(otp){
        this.setState({ enteredPin: otp, pinError: null })
    }

    async handlePinSubmit(otp){
        this.setState({ verifyingPin: true, pinError: null, enteredPin: otp }, async () => {
            try{
                const share = await verifyPin(this.props.match.params.token, this.state.enteredPin)
                this.setState({ verifyingPin: false, pinVerified: true });
            }catch(e){
                this.setState({ verifyingPin: false, pinError: e.message });
            }
        });
    }

    handleSuccessfulIssuance(){
        this.setState({ credentialIssued: true })
    }

    handleVerifyAndLink(){
        const share = this.state.share;
        this.setState({
            share
        });
    }

    render(){
        return !this.state.loadingShareRecord ? (
            !this.state.error ? (
                <div className='registration_page'>
                    <div className='registration_page__container'>
                        <div className='registration_page__header'>
                            <div className='registration_page__header__logo'><img src='/images/logo_full.png'/></div>
                            <div>
                                <div className='registration_page__header__title'>Verify Degree</div>
                                <p>You are verifying the degree of <b>{this.state.share.student.first_name} {this.state.share.student.last_name}</b></p>
                            </div>
                        </div>
                        <div className={`registration_breadcrumbs ${this.state.credentialIssued ? 'completed' : ''}`}>
                            <div className={`registration_breadcrumbs__item triangle ${!this.state.pinVerified ? 'active' : 'complete'}`}>
                                <div className='registration_breadcrumbs__item__label'>{!this.state.pinVerified ? 'Step 1' : 'Completed'}</div>
                                <div className='registration_breadcrumbs__item__description'>Enter Verify Code</div>
                                <div class="success-checkmark small">
                                    <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`registration_breadcrumbs__item triangle ${this.state.credentialIssued ? 'complete' : (this.state.pinVerified ? 'active' : '')}`}>
                                <div className='registration_breadcrumbs__item__label'>{!this.state.credentialIssued ? 'Step 2' : <HiOutlineCheck/>}</div>
                                <div className='registration_breadcrumbs__item__description'>Generate your Credential</div>
                                <div class="success-checkmark small">
                                    <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='registration_page__step_container'>
                            <div className={`registration_page__step ${!(this.state.pinVerified) ? 'active' : ''}`}>
                                {(this.state.pinVerified) && (
                                    <div class="success-checkmark">
                                        <div class="check-icon">
                                            <span class="icon-line line-tip"></span>
                                            <span class="icon-line line-long"></span>
                                            <div class="icon-circle"></div>
                                            <div class="icon-fix"></div>
                                        </div>
                                    </div>
                                )}
                                {this.state.pinVerified ? (
                                    <div>
                                        <div className='registration_page__step__title'>Pin Verified</div>
                                        <div className='university_badge'>
                                            <div className='university_badge__title'>Thanks {this.state.share.name}</div>
                                        </div>
                                        <p>You have succesfully verified your identity. You can now generate a login credential to view the degree of <b>{this.state.share.student.first_name} {this.state.share.student.last_name}</b></p>
                                    </div>
                                ) : (
                                    <div>                 
                                        <div className='registration_page__step__title'>Enter Verify Code</div>                       
                                        <p>Please enter the unique verification code emailed to you at <b>{this.state.share.email}</b> to verify your right to access this degree.</p>
                                        <form method='post' className='opt_form' onSubmit={this.handlePinSubmit}>
                                            <ReactCodeInput 
                                                onChange={this.handlePinChange}
                                                onComplete={this.handlePinSubmit}
                                                fields={4}
                                                disabled={this.state.verifyingPin}
                                                className={`opt_input ${this.state.pinError ? 'error' : ''}`}
                                            />
                                            <button type='submit' className='btn' disabled={this.state.verifyingPin}>Verifiy<HiArrowCircleRight/></button>
                                            {this.state.pinError && <div className='otp_error'>{this.state.pinError}</div>}
                                        </form>    
                                    </div>
                                )}

                            </div>
                            <div className={`registration_page__step ${this.state.credentialIssued ? 'complete' : ((this.state.pinVerified) ? 'active' : '')}`}>
                                {this.state.credentialIssued && <div class="success-checkmark">
                                <div class="check-icon">
                                    <span class="icon-line line-tip"></span>
                                    <span class="icon-line line-long"></span>
                                    <div class="icon-circle"></div>
                                    <div class="icon-fix"></div>
                                </div>
                            </div>}
                                <div className='registration_page__step__title'>{(this.state.credentialIssued) ? 'Credential Generated' : 'Generate Login Credential'}</div>
                                
                                {this.state.credentialIssued ? (
                                    <p>Credential succesfully added to Microsoft Authenticator wallet. Do not delete this credential as this is what will use to login into the portal in the future.</p>
                                ) : ((this.state.pinVerified) && (
                                    <div className='qr_form'>
                                        <p>Scan the below QR code to generate your degree login credential to view and verify the degree of <b>{this.state.share.student.first_name} {this.state.share.student.last_name}</b>. This credential will be added to your Microsoft Authenticator wallet and this is what you will use to login in the future.</p>
                                        <DegreeIssuanceGenerator token={this.props.match.params.token} onSuccessfulIssuance={this.handleSuccessfulIssuance}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : <div><Redirect to={'/login?error=' + this.state.error}/></div>
        ) : <Preloader fitToContent={true}/>;
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(EmployerGeneratePage);

import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/Login/LoginPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { history } from '../helpers/history';
import TranscriptsPage from '../components/Transcripts/TranscriptsPage';
import DegreesPage from '../components/Degrees/DegreesPage';
import CreateStudentLoginCredPage from '../components/StudentRegistration/CreateStudentLoginCredPage';
import EmployerGeneratePage from '../components/EmployerGeneratePage/EmployerGeneratePage';
import EmployerLoginPage from '../components/EmployerLoginPage/EmployerLoginPage';
import DegreePage from '../components/Degrees/DegreePage';
import OrganizationRoute from './OrganizationRoute';
import OrganizationLanding from '../components/OrganizationPortal/OrganizationLanding';

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <PrivateRoute path="/" exact={true} component={DegreesPage}/>
                <PrivateRoute path="/transcripts" exact={true} component={TranscriptsPage}/>
                <PrivateRoute path="/degrees/:id" component={DegreePage}/>
                <PrivateRoute path="/degrees" component={DegreesPage}/>
                <PublicRoute path="/create/:token" component={CreateStudentLoginCredPage}/>
                <PublicRoute path="/degree/:token/create" component={EmployerGeneratePage}/>
                <PublicRoute path="/degree/:token/login" component={EmployerLoginPage}/>
                <PublicRoute path="/create" component={CreateStudentLoginCredPage}/>
                <PublicRoute path="/login" exact={true} component={LoginPage}/>
                <OrganizationRoute path="/organization/:id/dashboard" component={OrganizationLanding}/>
                <Route component={NotFoundPage}/>
            </Switch>
        </div>
    </Router>
);

export default AppRouter;
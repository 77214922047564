import React from 'react';
import { connect } from 'react-redux'
import Preloader from '../Preloader';
import TranscriptsModal from '../Transcripts/TranscriptsModal';
import ShareModal from '../Sharing/ShareModal';
import StudentDegreeModal from '../StudentDegreeModal/StudentDegreeModal';
import StudentInfoComponent from './StudentInfoComponent';
import { getDegreeShares, getStudentDegrees, revokeShare } from '../../services/degrees';
import { history } from '../../helpers/history';
import ShareControlPanel from '../Sharing/ShareControlPanel';
import CertificatePreview from './CertificatePreview';
import { toTimestamp } from '../../services/dates';
import TranscriptsPage from '../Transcripts/TranscriptsPage';
import { FaGraduationCap } from 'react-icons/fa';

class DegreePage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            degree: null,
            loadingDegree: true,
            loadingPdfPreview: true,
            viewingTranscripts: false,
            sharingDegree: false,
            addingToWallet: false,
            managingShares: false,
            currentDisplay: 'transcripts'
        }

        this.handleViewTranscriptsToggle = this.handleViewTranscriptsToggle.bind(this);
        this.handleViewCertificateToggle = this.handleViewCertificateToggle.bind(this);
        this.handleShareDegreeTrigger = this.handleShareDegreeTrigger.bind(this);
        this.handleManageSharesTrigger = this.handleManageSharesTrigger.bind(this);
        this.handleAddToWalletTrigger = this.handleAddToWalletTrigger.bind(this);
        this.handleShareRevoke = this.handleShareRevoke.bind(this);
        this.refreshShares = this.refreshShares.bind(this);
    }

    async componentDidMount(){
        const degrees = await getStudentDegrees(this.props.auth.agent.token);
        const degree = degrees.find(d => d.id.toLowerCase() == this.props.match.params.id.toLowerCase());

        if(!degree){ return history.push('/degrees') }

        const shares = await getDegreeShares(this.props.auth.agent.token, degree.id);

        const currentDisplay = degree.date_obtained ? 'certificate' : 'transcripts'
        this.setState({ degree, degrees, currentDisplay, shares, loadingDegree: false })
    }

    handleViewTranscriptsToggle(){
        this.setState({ currentDisplay: 'transcripts' });
    }

    handleViewCertificateToggle(){
        this.setState({ currentDisplay: 'certificate' });
    }

    handleShareDegreeTrigger(){
        this.setState(prevState => ({ sharingDegree: !prevState.sharingDegree }))
    }

    handleManageSharesTrigger(){
        this.setState(prevState => ({ managingShares: !prevState.managingShares }))
    }

    handleAddToWalletTrigger(){
        this.setState(prevState => ({ addingToWallet: !prevState.addingToWallet }))
    }

    async handleShareRevoke(share){
        await revokeShare(this.props.auth.agent.token, share.id)
        const shares = await getDegreeShares(this.props.auth.agent.token, this.state.degree.id);
        this.setState({ shares })
    }

    async refreshShares(){
        const shares = await getDegreeShares(this.props.auth.agent.token, this.state.degree.id);
        this.setState({ shares })
    }

    render(){
        return this.state.loadingDegree ? <Preloader fitToContent={true}/> : (
            <div className="degree_page">
                {this.state.currentDisplay === 'certificate' ? <CertificatePreview degree={this.state.degree}/> : <TranscriptsPage degree={this.state.degree}/>}
                <div className='degree_student_info'>
                    <div>
                        {this.props.auth.agent.share && (
                            <div className='share_badge'>
                                <div className='share_bade__heading'>External Access</div>
                                <div className='share_bade__subheading'>Welcome <b>{this.props.auth.agent.share.name}</b></div>
                                <div className='share_bade__company'>{this.props.auth.agent.share.organization}</div>
                                <p>You are verifying the degree of <b>{this.state.degree.degree_name}</b></p>
                            </div>
                        )}
                        <div className='degree_student_info__logo'><img src={this.props.auth.university.logo} alt='Insitution Logo'/></div>
                        <div className='degree_student_info__title text_truncate'>{this.props.auth.agent.first_name} {this.props.auth.agent.last_name}</div>
                        <div className='degree_student_info__subtitle_small'>{this.props.auth.university.name}</div>
                        <div className='degree_student_info__subtitle'>{this.state.degree.degree_name}</div>
                        <div className='degree_student_info__date_obtained'>{this.state.degree.date_obtained ? <div className='completed'>Date obtained: {toTimestamp(this.state.degree.date_obtained)} <FaGraduationCap/></div> : 'Not yet obtained, currently in progress'}</div>
                        <StudentInfoComponent/>
                    </div>
                    {this.state.degree.date_obtained && !this.props.auth.agent.share && (
                        <button className='add_to_ad' onClick={this.handleAddToWalletTrigger}>
                            <img src='/images/azure_authenticor_icon.svg'/>
                            <div className='add_to_ad_content'>
                                <div>Add degree to</div>
                                <div className='add_to_ad_content__value'>Azure Authenticator</div>
                            </div>
                        </button>
                        )}
                    <div className='degree_actions'>
                        {(this.state.currentDisplay === 'certificate' && (!this.props.auth.agent.share || (this.props.auth.agent.share && this.props.auth.agent.share.include_transcripts !== false))) && <button onClick={this.handleViewTranscriptsToggle}>View Academic Transcripts</button>}
                        {(this.state.currentDisplay === 'transcripts' && this.state.degree.date_obtained) && <button onClick={this.handleViewCertificateToggle}>View Degree Certificate</button>}
                        {(!this.props.auth.agent.share && (this.state.shares && this.state.shares.length > 0)) && <button onClick={this.handleManageSharesTrigger}>Manage Shares</button>}
                        {!this.props.auth.agent.share && <button onClick={this.handleShareDegreeTrigger}>Share With Employer</button>}
                    </div>
                </div>
                {this.state.viewingTranscripts && (
                    <TranscriptsModal isOpen={this.state.viewingTranscripts} onRequestClose={this.handleViewTranscriptsToggle} student={this.props.auth.agent} degree={this.state.degree}/>
                )}
                {this.state.sharingDegree && (
                    <ShareModal isOpen={this.state.sharingDegree} refreshShares={this.refreshShares} onRequestClose={this.handleShareDegreeTrigger} degrees={this.state.degrees} degree={this.state.degree}/>
                )}
                {this.state.addingToWallet && (
                    <StudentDegreeModal isOpen={this.state.addingToWallet} degree={this.state.degree} onRequestClose={this.handleAddToWalletTrigger}/>
                )}
                {this.state.managingShares && (
                    <ShareControlPanel isOpen={this.state.managingShares} onRevoke={this.handleShareRevoke} shares={this.state.shares} degree={this.state.degree} onRequestClose={this.handleManageSharesTrigger}/>
                )}
            </div>
        ); 
    };
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(DegreePage);
import React from 'react';
import { connect } from 'react-redux';
import DashboardSelectedComponent from './DashboardSelectedComponent';
import Menu from './Menu/Menu';

class Dashboard extends React.Component{
    constructor(props){
        super(props);

        this.statusTimer = undefined;
        this.state = {
            mobileMenuOpen: false
        };
        
        this.mobileMenuTrigger = this.mobileMenuTrigger.bind(this);
    }

    mobileMenuTrigger(){
        this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
    }

    render(){
        return (
            <div className="dashboard">
                <Menu/>
                <div className='dashboard_container'>
                    <div className="dashboard_content">
                        <DashboardSelectedComponent>{this.props.component}</DashboardSelectedComponent>
                    </div>
                </div>
            </div>
        ); 
    };
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Dashboard);
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { HiCheckCircle, HiOutlineX, HiXCircle } from 'react-icons/hi';
import { toDateOnly } from '../../services/dates';
import moment from 'moment';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';

class ShareControlPanel extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            shareToRevoke: null
        };

        this.handleShareRevokeClick = this.handleShareRevokeClick.bind(this);
        this.handleShareRevokeConfirm = this.handleShareRevokeConfirm.bind(this);
    }

    handleShareRevokeClick(shareToRevoke){
        this.setState({ shareToRevoke });
    }

    async handleShareRevokeConfirm(){
        const shareToRevoke = this.state.shareToRevoke;
        this.setState({ revokingAccess: true, shareToRevoke: null })

        await this.props.onRevoke(shareToRevoke);

        this.setState({ revokingAccess: false })
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal share_manager" onRequestClose={this.props.onRequestClose}>
                <div className='modal_title'>Manage degree shares</div>
                <table className='share_table table'>
                    <thead>
                        <tr>
                            <th>Shared with</th>
                            <th>Info Shared</th>
                            <th>Valid Until</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.shares.map(share => {
                            const expired = !share.expires_at || !moment(share.expires_at).isSameOrAfter(moment().subtract(1,'days').endOf('day'));
                            return (
                                <tr className={`${expired ? 'expired' : ''}`}>
                                    <td className='shared_name'>
                                        <div>{share.name} ({share.organization})</div>
                                        <div className='timestamp'>{share.email}</div>
                                    </td>
                                    <td>
                                        <div className='info_included'>{share.include_contact_info ? <HiCheckCircle className='check'/> : <HiXCircle className='cross'/>}Contact Info</div>
                                        <div className='info_included'>{share.include_id ? <HiCheckCircle className='check'/> : <HiXCircle className='cross'/>}ID Number</div>
                                        <div className='info_included'>{share.include_transcripts ? <HiCheckCircle className='check'/> : <HiXCircle className='cross'/>}Transcripts</div>
                                    </td>
                                    <td>
                                        <div className='timestamp'>Date shared: {toDateOnly(share.date_created)}</div>
                                        {expired ? <div className='expired-badge'>Expired</div> : <div className='timestamp'>Valid until: {toDateOnly(share.expires_at)}</div>}
                                    </td>
                                    {<td>{!expired && (share.revoked ? <div className='access_revoked_message'>Access Revoked</div> : <button className='btn cancel' onClick={() => { this.handleShareRevokeClick(share); }}>Revoke</button>)}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="modal__actions">
                    <button onClick={this.props.onRequestClose} className="btn cancel">Close</button>
                </div>
                <div className='modal_close' onClick={this.props.onRequestClose}><HiOutlineX/></div>
                {this.state.shareToRevoke && <ConfirmModal onConfirm={this.handleShareRevokeConfirm} confirmText="Revoke Access" onRequestClose={() => { this.handleShareRevokeClick(); }} isOpen={this.state.shareToRevoke} title="Revoke Access" description={<p>You are about to revoke access to view and verify your <b>{this.props.degree.degree_name}</b> degree from <b>{this.state.shareToRevoke.name}.</b> Are you sure you want to do this?</p>}/>}
                {this.state.revokingAccess && <LoadingModal isOpen={this.state.revokingAccess} text="Revoking access"/>}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ShareControlPanel);
import React from "react";
import { BsShieldLockFill } from "react-icons/bs";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import "flag-icons";

class VaultList extends React.Component{
    constructor(props){
        super(props);

        this.vaults = [{
            title: 'Degrees | Fusion UK',
            numberOfDegrees: [17],
            icon: 'gb'
        },{
            title: 'Degrees | Fusion Spain',
            numberOfDegrees: [59],
            icon: 'es'
        }, {
            title: 'Degrees | Fusion Australia',
            numberOfDegrees: [30],
            icon: 'au'
        }, {
            title: 'Degrees | Fusion Cape Town',
            numberOfDegrees: [51],
            icon: 'za'
        }, {
            title: 'Degrees | Fusion France',
            numberOfDegrees: [82],
            icon: 'fr'
        }];
    }
    render(){
        return(
            <div className="vaults">
                <h3>Your Vaults</h3>
                <div className="vault_list">
                    {this.vaults.map(vault => (
                        <NavLink className="vault_list_item" to={'/organization/8c045cfd-6a5f-4075-b047-84dfe4e1625c/dashboard/vault/7c35bf16173d46a1a69abff09c7190ba'}>
                            <div className="vault_list_item__icon"><span class={'.fi fi-' + vault.icon}></span></div>
                            <div className="vault_list_item__title">{vault.title}</div>
                            <div className="vault_list_item__subtitle">{vault.numberOfDegrees} documents</div>       
                            <div className="vault_list_item__lock"><BsShieldLockFill/></div>
                        </NavLink>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(VaultList);
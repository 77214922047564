import React from 'react';
import { connect } from 'react-redux';
import { createLoginPresentationRequest } from '../../services/entra'
import socket from '../../services/socket';
import Preloader from '../Preloader';
import { studentActions } from '../../actions/students'
import AlertBox from '../AlertBox';
import moment from 'moment';
import Countdown from 'react-countdown';
import { timerRenderer } from '../../services/misc';

class LoginEntraForm extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            loadingPresentation: true,
            presentationRequestError: null,
            callbackInfo: null,
            awaitingCallback: false
        }

        this.handleCreateRequest = this.handleCreateRequest.bind(this);
        this.handleCodeRefresh = this.handleCodeRefresh.bind(this);
    }

    async componentDidMount(){
        socket.on('presentationRequestRetrieved', ({ state }) => {
            if(this.state.presentationRequest && this.state.presentationRequest.state === state){
                this.setState({ awaitingCallback: true, error: null });
            }
        });

        socket.on('presentationRequestVerified', (body) => {
            if(this.state.presentationRequest && this.state.presentationRequest.state === body.state){
                const { dispatch } = this.props;
                dispatch(studentActions.loginStudentWithPayload(body));
            }
        });

        socket.on('loginError', (body) => {
            if(this.state.presentationRequest && this.state.presentationRequest.state === body.state){
                this.setState({ error: body.error })
            }
        });

        this.handleCreateRequest();
    }

    async handleCreateRequest(){
        if(this.state.awaitingCallback){ this.setState({ awaitingCallback: false, error: 'You did not present your credential in time. Please re-scan the below QR code and present your login credential' }) }
        
        this.setState({ loadingPresentation: true })
        try{
            const presentationRequest = await createLoginPresentationRequest();
            this.setState({
                presentationRequest,
                presentationRequestError: null,
                loadingPresentation: false,
                timeRemaining: moment.unix(presentationRequest.expiry)
            });

            const interval = setInterval(() => {
                if(moment().isSameOrAfter(this.state.timeRemaining)){
                    clearInterval(this.state.currentInterval);
                    this.handleCreateRequest();
                }
            }, 1000);

            this.setState({ currentInterval: interval });
        }
        catch(e){
            this.setState({ presentationRequestError: e })
        }
    }

    handleCodeRefresh(){
        clearInterval(this.state.currentInterval);
        this.setState({ awaitingCallback: false }, () => {
            this.handleCreateRequest();
        });
    }

    componentWillUnmount(){
        socket.off('presentationRequestRetrieved');
        socket.off('presentationRequestVerified');
        socket.off('loginError');
    }

    render(){
        return !this.state.loadingPresentation ? (!this.state.awaitingCallback ? (
            <div className='qr_form'>
                <img className='login_code' src={this.state.presentationRequest.qrCode}/>
                {1 == 2 && <a className='add_to_ad' href={this.state.presentationRequest.url}>
                    <img src='/images/azure_authenticor_icon.svg'/>
                    <div className='add_to_ad_content'>
                        <div>Login with</div>
                        <div className='add_to_ad_content__value'>Azure Authenticator</div>
                    </div>
                </a>}
                {this.state.timeRemaining && <div className='expiry_countdown'>Expires In: <Countdown date={moment(this.state.timeRemaining)} renderer={timerRenderer}/></div>}
                {this.state.error && <AlertBox type="alert alert-danger mb-medium">{this.state.error}</AlertBox>}
                <a href=''>Download Microsoft Authenticator</a>
                <div className='store_badges'>
                    <img src='/images/google_badge.png'/>
                    <img src='/images/apple_badge.png'/>
                </div>
            </div>
        ) : (
            <div className='qr_form_loader'>
                <Preloader  padding="60px 0px"/>
                <div className='waiting_for_auth__label'>Please authenticate the request using your authenticator app.</div>
                <div className='regen_code_button' onClick={this.handleCodeRefresh}>Refresh</div>
                {this.state.timeRemaining && <div className='expiry_countdown'>Expires In: <Countdown date={moment(this.state.timeRemaining)} renderer={timerRenderer}/></div>}
                {this.state.error && <AlertBox type="alert alert-danger mt-medium">{this.state.error}</AlertBox>}
            </div>
        )) : (
            <div className='qr_form_loader'>
                <Preloader padding="60px 0px"/>
            </div>
        );
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(LoginEntraForm);


import { handleResponse } from './misc';

const getStudentDegrees = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/student/degrees', requestOptions).then(handleResponse).then((degrees) => {
        return degrees;
    });
}

const getDegreeShares = (authToken, degreeId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/student/degree/' + degreeId + '/shares', requestOptions).then(handleResponse).then((shares) => {
        return shares; 
    });
}

const generateDegreePdf = (authToken, degreeId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/student/degrees/'+degreeId+'/generate', requestOptions).then(handleResponse).then((pdfResponse) => {
        return pdfResponse; 
    });
}

const shareDegree = (authToken, degrees, contactPersonName, contactPersonEmail, organization, include_id_number, include_contact_info, include_transcripts, expires_at) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            contactPersonName,
            contactPersonEmail,
            organization,
            include_id_number,
            include_contact_info,
            include_transcripts,
            expires_at,
            degrees
        })
    };

    return fetch('/api/share', requestOptions).then(handleResponse).then((degrees) => {
        return degrees;
    });
}

const getShare = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/share', requestOptions).then(handleResponse).then((share) => {
        return share;
    }).catch(e => {
        throw new Error(e)
    })
}

const revokeShare = (authToken, shareId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/share/'+ shareId +'/revoke', requestOptions).then(handleResponse).then(() => {
        return true;
    }).catch(e => {
        throw new Error(e)
    })
}

const verifyPin = (authToken, pin) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ pin })
    };

    return fetch('/api/share/verifypin', requestOptions).then(handleResponse).then((share) => {
        return share;
    }).catch(e => {
        throw new Error('Invalid pin')
    })
}


export { 
    getStudentDegrees,
    shareDegree,
    getDegreeShares,
    getShare,
    verifyPin,
    revokeShare,
    generateDegreePdf
}
import { handleResponse } from './misc';

const createLoginPresentationRequest = async () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/entra/createLoginPresentationRequest', requestOptions).then(handleResponse).then((presentationResponse) => {
        return presentationResponse;
    })
}

const createStudentVerifyAndLinkPresentaionRequest = async () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/entra/createStudentVerifyAndLinkPresentaionRequest', requestOptions).then(handleResponse).then((presentationResponse) => {
        return presentationResponse;
    })
}

const createShareVerifyAndLinkPresentaionRequest = async () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/entra/createShareVerifyAndLinkPresentaionRequest', requestOptions).then(handleResponse).then((presentationResponse) => {
        return presentationResponse;
    })
}

const createIssuanceRequest = async (authToken) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/entra/createLoginIssuanceRequest', requestOptions).then(handleResponse).then((issuanceResponse) => {
        return issuanceResponse;
    })
}

const createDegreeIssuanceRequest = async (authToken) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/entra/createDegreeIssuanceRequest', requestOptions).then(handleResponse).then((issuanceResponse) => {
        return issuanceResponse;
    })
}
const createStudentDegreeIssuanceRequest = async (degreeId, authToken) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ degree_id: degreeId })
    };

    return fetch('/api/entra/createStudentDegreeIssuanceRequest', requestOptions).then(handleResponse).then((issuanceResponse) => {
        return issuanceResponse;
    })
}

export {
    createLoginPresentationRequest,
    createStudentVerifyAndLinkPresentaionRequest,
    createShareVerifyAndLinkPresentaionRequest,
    createIssuanceRequest,
    createDegreeIssuanceRequest,
    createStudentDegreeIssuanceRequest
}
import { studentService } from '../services/students';
import { history } from '../helpers/history';
import { alertActions } from './alerts';
import { getUniversity } from '../services/university';

function login(email, password){
    return (dispatch) => {
        dispatch(request({ email }));

        studentService.login(email, password).then(async (student) => {
            const university = await getUniversity(student.token, student.university_id)
            localStorage.setItem('university', JSON.stringify(university));
            dispatch(success(student, university));
            history.push('/');
        }, (error) => {
            dispatch(failure(error))
            dispatch(alertActions.error(error))
        });
    };

    function request(agent){ 
        return {
            type: 'AGENT_LOGIN_REQUEST',
            agent
        } 
    }

    function success(agent, university){ 
        return {
            type: 'AGENT_LOGIN_SUCCESS',
            agent,
            university
        } 
    }

    function failure(error){ 
        return {
            type: 'AGENT_LOGIN_FAILURE',
            error
        } 
    }
};

function refreshStudentSession(payload){
    return (dispatch) => {
        const student = payload;
        localStorage.setItem('user', JSON.stringify(student));

        dispatch(request({ email: student.email }));
        getUniversity(student.token, student.university_id).then(university => {
            localStorage.setItem('university', JSON.stringify(university));
            dispatch(success(student, university));
        })
    };

    function request(agent){ 
        return {
            type: 'AGENT_LOGIN_REQUEST',
            agent
        } 
    }

    function success(agent, university){ 
        return {
            type: 'AGENT_LOGIN_SUCCESS',
            agent,
            university
        } 
    }
}

function loginStudentWithPayload(payload){
    return (dispatch) => {
        const student = payload;
        localStorage.setItem('user', JSON.stringify(student));

        dispatch(request({ email: student.email }));
        getUniversity(student.token, student.university_id).then(university => {
            localStorage.setItem('university', JSON.stringify(university));
            dispatch(success(student, university));
            history.push('/');
        })
    };

    function request(agent){ 
        return {
            type: 'AGENT_LOGIN_REQUEST',
            agent
        } 
    }

    function success(agent, university){ 
        return {
            type: 'AGENT_LOGIN_SUCCESS',
            agent,
            university
        } 
    }
};

function logout() {
    studentService.logout();
    return (dispatch) => {
        dispatch({ type: 'AGENT_LOGOUT' });
    }
}

function setCurrentStudent(agent){
    return {
        type: 'SET_CURRENT_AGENT_SET',
        agent
    }
}

export const studentActions = {
    login,
    logout,
    setCurrentStudent,
    loginStudentWithPayload,
    refreshStudentSession
}; 
import React from 'react';
import { connect } from 'react-redux'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Preloader from '../Preloader';
import { generateDegreePdf } from '../../services/degrees';

class CertificatePreview extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingPdfPreview: true,
            loadingDegree: true,
            loadingPdfPreview: true
        }
    }

    async componentDidMount(){
        const degree = this.props.degree;
        if(degree.date_obtained){
            const generatedDegree = await generateDegreePdf(this.props.auth.agent.token, degree.id);
            degree.certificate_watermark = generatedDegree.certificate_watermark;
        }

        this.setState({ degree, loadingDegree: false })
    }

    render(){
        return (
            <div className="degree_content_main">
                {this.state.loadingDegree ? <Preloader fitToContent={true}/> : (
                    <div className='degree_preview'>
                        <Document file={this.state.degree.certificate_watermark} onLoadSuccess={() => { this.setState({ loadingPdfPreview: false }) }}>
                            <Page pageNumber={1}/>
                        </Document>
                        {this.state.loadingPdfPreview && <div className='degree_preview_loader'><Preloader fitToContent={true}/></div>}
                    </div>
                )} 
            </div>
        )
    };
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(CertificatePreview);
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { HiOutlineX } from 'react-icons/hi';
import StudentDegreeIssuanceGenerator from './StudentDegreeIssuanceGenerator';

class StudentDegreeModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            credentialIssued: false
        };

        this.handleSuccessfulIssuance = this.handleSuccessfulIssuance.bind(this);
    }

    handleSuccessfulIssuance(){
        this.setState({ credentialIssued: true })
    }
    
    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal student_degree_modal" onRequestClose={this.props.onRequestClose}>
                <div className='student_degree_modal__container'>
                    <div className='registration_page__header'>
                        <div className='registration_page__header__logo'><img src='/images/logo_full.png' alt="EduVault Logo"/></div>
                        <div>
                            <div className='registration_page__header__title'>Add Degree To Wallet</div>
                        </div>
                    </div>
                    <div className={`registration_page__step ${this.state.credentialIssued ? 'complete' : ((!this.state.credentialIssued) ? 'active' : 'complete')}`}>
                        {this.state.credentialIssued && <div class="success-checkmark">
                        <div class="check-icon">
                            <span class="icon-line line-tip"></span>
                            <span class="icon-line line-long"></span>
                            <div class="icon-circle"></div>
                            <div class="icon-fix"></div>
                        </div>
                    </div>}
                        <div className='registration_page__step__title'>{(this.state.credentialIssued) ? 'Credential Successfully Added' : 'Scan Degree Credential'}</div>
                        
                        {this.state.credentialIssued ? (
                            <div className='registration_page__step__success'>
                                <p>Credential succesfully added to Microsoft Authenticator wallet.</p>
                                <button className='btn cancel mt-medium' onClick={this.props.onRequestClose}>Dismiss</button>
                            </div>
                        ) : (
                            <div className='qr_form'>
                                <p>Scan the below QR code to generate your student degree credential. This credential will be added to your Microsoft Authenticator wallet.</p>
                                <StudentDegreeIssuanceGenerator degree={this.props.degree} onRequestClose={this.props.onRequestClose} onSuccessfulIssuance={this.handleSuccessfulIssuance}/>
                            </div>
                        )}
                    </div>
                </div>
                <div className='modal_close' onClick={this.props.onRequestClose}><HiOutlineX/></div>
            </Modal>)
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(StudentDegreeModal);
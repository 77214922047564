import React from 'react';
import { connect } from 'react-redux';
import MenuItem from './MenuItem';
import { studentActions } from '../../actions/students';
import { HiOutlineX } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { MdOutlineGrading } from 'react-icons/md'

class Menu extends React.Component{
    constructor(props){
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
        this.props.dispatch(studentActions.logout());
    }

    render(){
        return (
            <div className={`dashboard_menu ${this.props.mobileMenuOpen ? 'mobile_expanded' : ''}`}>
                <div className="dashboard_menu__top_bar">
                    <div className="dashboard_topbar__menu_trigger" onClick={this.props.mobileMenuTrigger}><HiOutlineX/></div>
                    <NavLink to='/'><img className="dashboard_topbar__full_logo" src="/images/logo_full.png" alt="Mobile Logo"></img></NavLink>
                </div>
                <div className="dashboard_menu__inner_top">
                    <NavLink to='/'><img className="dashboard_menu__logo" src='/images/logo_full.png' alt="EduVault Logo"/></NavLink>
                </div>
                <div className="dashboard_menu__inner_bottom">
                    {(this.props.auth && this.props.auth.agent && this.props.auth.agent.degreeCount && this.props.auth.agent.degreeCount > 1) ? ( 
                        <MenuItem to="/degrees"><MdOutlineGrading/>Degrees</MenuItem>
                    ) : ''}
                    <div className='btn cancel' onClick={this.handleLogout}>Logout</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Menu);
import React from 'react';
import { connect } from 'react-redux';
import { getShare, verifyPin } from '../../services/degrees';
import { Redirect } from 'react-router-dom';
import Preloader from '../Preloader';
import ReactCodeInput from 'react-verification-code-input';
import { HiArrowCircleRight } from 'react-icons/hi';
import { studentActions } from '../../actions/students';
import { history } from '../../helpers/history';

class EmployerLoginPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingShareRecord: true,
            error: null,
            share: null,
            pinVerified: false,
            enteredPin: ''
        }

        this.handlePinChange = this.handlePinChange.bind(this);
        this.handlePinSubmit = this.handlePinSubmit.bind(this);
    }

    async componentDidMount(){
        const token = this.props.match.params.token;
        try{
            const share = await getShare(token);
            if(share.employerEntraEnabled){ return history.push('/login'); }
            this.setState({ share, loadingShareRecord: false })    
        }catch(e){
            this.setState({ error: e.message, loadingShareRecord: false, enteredPin: '' })
        }
    }

    handlePinChange(otp){
        this.setState({ enteredPin: otp, pinError: null })
    }

    async handlePinSubmit(otp){
        this.setState({ verifyingPin: true, pinError: null, enteredPin: otp }, async () => {
            try{
                const loginPayload = await verifyPin(this.props.match.params.token, this.state.enteredPin);
                this.setState({ verifyingPin: false, pinVerified: true });

                const { dispatch } = this.props;
                setTimeout(() => {
                    dispatch(studentActions.loginStudentWithPayload(loginPayload));
                }, 2000)
            }catch(e){
                this.setState({ verifyingPin: false, pinError: e.message });
            }
        });
    }

    render(){
        return !this.state.loadingShareRecord ? (
            !this.state.error ? (
                <div className='registration_page'>
                    <div className='registration_page__container pin'>
                        <div className='registration_page__header'>
                            <div className='registration_page__header__logo'><img src='/images/logo_full.png'/></div>
                            <div>
                                <div className='registration_page__header__title'>Verify Degree</div>
                                <p>You are verifying the degree of <b>{this.state.share.student.first_name} {this.state.share.student.last_name}</b></p>
                            </div>
                        </div>
                        <div className='registration_page__step_container'>
                            <div className={`registration_page__step ${!(this.state.pinVerified) ? 'active' : 'complete'}`}>
                                {(this.state.pinVerified) && (
                                    <div class="success-checkmark">
                                        <div class="check-icon">
                                            <span class="icon-line line-tip"></span>
                                            <span class="icon-line line-long"></span>
                                            <div class="icon-circle"></div>
                                            <div class="icon-fix"></div>
                                        </div>
                                    </div>
                                )}
                                {this.state.pinVerified ? (
                                    <div>
                                        <div className='registration_page__step__title'>Pin Verified</div>
                                        <div className='university_badge'>
                                            <div className='university_badge__title'>Thanks {this.state.share.name}</div>
                                        </div>
                                        <p>You have succesfully verified your identity. You can now generate a login credential to view the degree of <b>{this.state.share.student.first_name} {this.state.share.student.last_name}</b></p>
                                    </div>
                                ) : (
                                    <div>                 
                                        <div className='registration_page__step__title'>Enter Verify Code</div>                       
                                        <p>Please enter the unique verification code emailed to you at <b>{this.state.share.email}</b> to verify your right to access this degree.</p>
                                        <form method='post' className='opt_form' onSubmit={this.handlePinSubmit}>
                                            <ReactCodeInput 
                                                onChange={this.handlePinChange}
                                                onComplete={this.handlePinSubmit}
                                                fields={4}
                                                disabled={this.state.verifyingPin}
                                                className={`opt_input ${this.state.pinError ? 'error' : ''}`}
                                            />
                                            <button type='submit' className='btn' disabled={this.state.verifyingPin}>Verifiy<HiArrowCircleRight/></button>
                                            {this.state.pinError && <div className='otp_error'>{this.state.pinError}</div>}
                                        </form>    
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : <div><Redirect to={'/login?error=' + this.state.error}/></div>
        ) : <Preloader fitToContent={true}/>;
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(EmployerLoginPage);
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { HiOutlineX } from 'react-icons/hi';
import AlertBox from '../AlertBox';
import validator from 'validator';
import ConfirmModal from '../Base/ConfirmModal';
import { studentService } from '../../services/students';
import LoadingModal from '../Base/LoadingModal';
import { studentActions } from '../../actions/students';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class EditStudentInfoModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            updates: {},
            error: null
        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.isValidForm = this.isValidForm.bind(this);
        this.handleStudentUpdatesSubmit = this.handleStudentUpdatesSubmit.bind(this);
        this.handleStudentInfoUpdateConfirmTrigger = this.handleStudentInfoUpdateConfirmTrigger.bind(this);
        this.handleStudentUpdate = this.handleStudentUpdate.bind(this);
    };

    componentDidMount(){
        let updates = {};
        this.props.fields.forEach(field => {
            updates = {
                ...updates,
                [field.name]: field.value
            }
        });

        this.setState({ updates })
    }

    isValidForm(){
        let isValid = true;
        this.props.fields.forEach(field => {
            if(!this.state.updates[field.name] || this.state.updates[field.name] === ''){ isValid = false; }

            if(!(this.state.updates[field.name] && this.state.updates[field.name].length <= field.maxLength)){ isValid = false; }
        })

        if(this.state.updates.email){
            // Ensure email is valid format
            if(!validator.isEmail(this.state.updates.email)){ isValid = false; }
        }

        return isValid;
    }

    handleFieldChange(e){
        let updates = this.state.updates;
        updates[e.target.name] = e.target.value;
        this.setState({ updates });
    }

    handleStudentUpdatesSubmit(e){
        e.preventDefault();

        if(this.isValidForm()){
            this.handleStudentInfoUpdateConfirmTrigger();
        }else{
            this.setState({ error: 'Invalid form. Please check your data is valid.' })
        }
    }

    handleStudentInfoUpdateConfirmTrigger(){
        this.setState(prevState => ({ confirmUpdates: !prevState.confirmUpdates }))
    }

    async handleStudentUpdate(){
        try{
            this.setState({ updatingStudent: true, error: null, confirmUpdates: false });
            const updatedStudent = await studentService.updateStudent(this.props.auth.agent.token, this.state.updates);
            this.props.dispatch(studentActions.setCurrentStudent(updatedStudent))
            this.setState({ updatingStudent: false })

            this.props.onRequestClose();
        }catch(e){
            this.setState({ updatingStudent: false, error: e })
        }
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal share_modal" onRequestClose={this.props.onRequestClose}>
                <div className='modal_title'>Update your info</div>
                <p>Edit your contact and profile info. Ensure you keep your profile up to date with your latest information.</p>
                <form method='POST' onSubmit={this.handleStudentUpdatesSubmit}>
                    {this.props.fields.length > 0 ? this.props.fields.map(field => (
                        <div className='form-group'>
                            <label>{field.label}</label>
                            {field.type !== 'phone_number' ? (                            
                                <input required={true} type={field.type} maxLength={field.maxLength} name={field.name} value={this.state.updates[field.name]} placeholder={'Enter your ' + field.name} onChange={this.handleFieldChange}/>
                            ) : (
                                <PhoneInput
                                    country={'us'}
                                    value={this.state.updates[field.name]}
                                    onChange={(value, data, event, formattedValue) => this.handleFieldChange({ target: { name: field.name, value: formattedValue} })}
                                    masks={{za: '(..) ... ....'}}
                                    enableLongNumbers={false}
                                    copyNumbersOnly={true}
                                    enableSearch={true}
                                />
                            )}
                        </div>
                    )) : <p>No fields to edit</p>}
                    {this.state.error && <AlertBox type="alert alert-danger mt-medium mb-medium">{this.state.error}</AlertBox>}
                    <div className="modal__actions">
                        <button type='submit' disabled={!this.isValidForm()} className="btn">Update Info</button>
                        <button className="btn cancel" onClick={this.props.onRequestClose}>Cancel</button>
                    </div>
                </form>
                <div className='modal_close' onClick={this.props.onRequestClose}><HiOutlineX/></div>
                {this.state.confirmUpdates && <ConfirmModal isOpen={this.state.confirmUpdates} onRequestClose={this.handleStudentInfoUpdateConfirmTrigger} onConfirm={this.handleStudentUpdate} confirmText="Update Info" title="Confirm Contact Info Updates" description={(
                    <div>
                        <p>You are about to update your contact info. Please confirm these updates are correct:</p>
                        <table class='share_details'>
                            <thead>
                                <tr><th colSpan={2}>Contact info</th></tr>
                            </thead>
                            <tbody>
                                {this.props.fields.map(field => (
                                    <tr>
                                        <th>{field.label}</th>
                                        <td>{this.state.updates[field.name]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}/>}
                {this.state.updatingStudent && <LoadingModal isOpen={this.state.updatingStudent} text="Saving contact info"/>}
            </Modal>)
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(EditStudentInfoModal);
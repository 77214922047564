import { connect } from 'react-redux';
import React from 'react';
import { history } from '../../helpers/history';
import Preloader from '../Preloader';
import { getStudentFromAd, getUserProfile } from '../../services/graph';
import AlertBox from '../AlertBox';
import LoginIssuanceGenerator from './LoginIssuanceGenerator';
import { HiOutlineCheck } from 'react-icons/hi'

class CreateStudentLoginCredPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loadingPage: true,
            adToken: null,
            adUser: null,
            studentProfileError: null,
            credentialIssued: false
        }

        this.handleSuccessfulIssuance = this.handleSuccessfulIssuance.bind(this);
        this.handleMicrosoftLogout = this.handleMicrosoftLogout.bind(this);
        //this.handleVerifyAndLink = this.handleVerifyAndLink.bind(this);
    }

    async componentDidMount(){
        let adToken = localStorage.getItem('adToken');
        let credentialIssued = localStorage.getItem('credentialIssued');
        
        if(this.props.match.params.token){
            localStorage.setItem('adToken', this.props.match.params.token);
            adToken = this.props.match.params.token;

            history.push('/create');
            return;
        }

        if(adToken){
            this.setState({ adToken });

            try{
                const adUser = await getUserProfile(adToken);

                if(adUser.error && adUser.error.code === 'InvalidAuthenticationToken'){
                    // TODO Logoout and kick user out
                    this.handleMicrosoftLogout();
                    
                    history.push('/create');
                }
                
                const student = await getStudentFromAd(adToken)
                this.setState({ adUser, student })
            }catch(e){
                this.setState({ studentProfileError: e })
                this.handleMicrosoftLogout();
            }
        }

        this.setState({ loadingPage: false, credentialIssued })
    }

    loginWithMicrosoft(){
        window.location.replace('/api/ad/signin')
    }

    handleMicrosoftLogout(){
        localStorage.clear('adToken');
        localStorage.clear('credentialIssued');
        this.setState({
            adToken: null,
            student: null,
            adUser: null
        });
    }

    handleSuccessfulIssuance(){
        localStorage.setItem('credentialIssued', true);
        this.setState({ credentialIssued: true })
    }

    // handleVerifyAndLink(){
    //     const student = this.state.student;
    //     this.setState({
    //         student
    //     });
    // }

    render(){
        return !this.state.loadingPage ? (
            <div className='registration_page'>
                <div className='registration_page__container'>
                    <div className='registration_page__header'>
                        <div className='registration_page__header__logo'><img src='/images/logo_full.png' alt="EduVault Logo"/></div>
                        <div>
                            <div className='registration_page__header__title'>Setup your account</div>
                            <div className={`registration_breadcrumbs ${this.state.credentialIssued ? 'completed' : ''}`}>
                                <div className={`registration_breadcrumbs__item triangle ${!(this.state.adUser && this.state.student && this.state.student.university) ? 'active' : 'complete'}`}>
                                    <div className='registration_breadcrumbs__item__label'>{!(this.state.adUser && this.state.student && this.state.student.university) ? 'Step 1' : 'Completed'}</div>
                                    <div className='registration_breadcrumbs__item__description'>Login with Microsoft</div>
                                    <div class="success-checkmark small">
                                        <div class="check-icon">
                                            <span class="icon-line line-tip"></span>
                                            <span class="icon-line line-long"></span>
                                            <div class="icon-circle"></div>
                                            <div class="icon-fix"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`registration_breadcrumbs__item triangle ${this.state.credentialIssued ? 'complete' : ((this.state.adToken && this.state.student && this.state.student.university) ? 'active' : '')}`}>
                                    <div className='registration_breadcrumbs__item__label'>{!this.state.credentialIssued ? 'Step 2' : <HiOutlineCheck/>}</div>
                                    <div className='registration_breadcrumbs__item__description'>Generate your Credential</div>
                                    <div class="success-checkmark small">
                                        <div class="check-icon">
                                            <span class="icon-line line-tip"></span>
                                            <span class="icon-line line-long"></span>
                                            <div class="icon-circle"></div>
                                            <div class="icon-fix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='registration_page__step_container'>
                        <div className={`registration_page__step ${!(this.state.adUser && this.state.student && this.state.student.university) ? 'active' : ''}`}>
                            {(this.state.adUser && this.state.student && this.state.student.university) && (
                                <div class="success-checkmark">
                                    <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                    </div>
                                </div>
                            )}
                            {this.state.adUser && this.state.student && this.state.student.university ? (
                                <div>
                                    <div className='registration_page__step__title'>Thanks, {this.state.adUser.displayName}</div>
                                    <div className='university_badge'>
                                        <div className='university_badge__title'>{this.state.student.university.name}</div>
                                    </div>
                                    <p>You have succesfully authenticated your Microsoft account. You can now generate your login credential for <b>{this.state.adUser.mail}</b></p>
                                </div>
                            ) : (
                                <div>
                                    <div className='registration_page__step__title'>Login with Microsoft</div>
                                    <p>Use your university Microsoft account to generate your login credential</p>
                                    <div onClick={this.loginWithMicrosoft} className='signin_microsoft_button'><img src='/images/microsoft_logo.png' alt="Microsoft Logo"/>Sign in with Microsoft</div>
                                    {this.state.studentProfileError && <AlertBox type="alert alert-danger mt-medium">{this.state.studentProfileError}</AlertBox>}
                                </div>
                            )}

                        </div>
                        <div className={`registration_page__step ${this.state.credentialIssued ? 'complete' : ((this.state.adToken && this.state.student && this.state.student.university) ? 'active' : '')}`}>
                            {this.state.credentialIssued && <div class="success-checkmark">
                            <div class="check-icon">
                                <span class="icon-line line-tip"></span>
                                <span class="icon-line line-long"></span>
                                <div class="icon-circle"></div>
                                <div class="icon-fix"></div>
                            </div>
                        </div>}
                            <div className='registration_page__step__title'>{(this.state.credentialIssued) ? 'Credential Generated' : 'Generate Login Credential'}</div>
                            
                            {this.state.credentialIssued ? (
                                <p>Credential succesfully added to Microsoft Authenticator wallet. Do not delete this credential as this is what will use to login into the portal in the future.</p>
                            ) : ((this.state.adToken && this.state.student && this.state.student.university) && (
                                <div className='qr_form'>
                                    <p>Scan the below QR code to generate your unique login credential to view your {this.state.student.university.name} degrees and transcripts. This credential will be added to your Microsoft Authenticator wallet and this is what you will use to login in the future.</p>
                                    <div className='regen_code_button logout_microsoft' onClick={this.handleMicrosoftLogout}>Login with Different Microsoft Account</div>
                                    <LoginIssuanceGenerator token={this.state.adToken} onSuccessfulIssuance={this.handleSuccessfulIssuance}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        ) : <Preloader fitToContent={true}/>;
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(CreateStudentLoginCredPage);
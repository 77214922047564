import { expiryTime, handleResponse } from './misc';

function login(email, password){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch('/api/students/login', requestOptions).then(handleLoginResponse).then((student) => {
        localStorage.setItem('user', JSON.stringify({ ...student, expiryTime: expiryTime() }));
        return student;
    })
}

function getStudent(authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/students/me', requestOptions).then(handleResponse).then((student) => {
        return student;
    })
}

function setStudent(authToken, studentId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/students/' + studentId, requestOptions).then(handleLoginResponse).then((student) => {
        const currentStudent = JSON.parse(localStorage.getItem('user'));
        currentStudent.student = student;
        localStorage.setItem('user', JSON.stringify({ ...currentStudent, expiryTime: expiryTime() }));
        return student;
    })
}

function updateStudent(authToken, updates){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(updates)
    };

    return fetch('/api/student', requestOptions).then(handleResponse).then((updatedStudent) => {
        return updatedStudent;
    });
}

function logout(){
    localStorage.removeItem('user');
}

function handleLoginResponse(response){
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            if(response.status === 400){
                logout();
            }
            
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export const studentService = {
    login,
    logout,
    setStudent,
    updateStudent,
    getStudent
};
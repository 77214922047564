import { connect } from 'react-redux';
import React from 'react';
import LoginForm from './LoginForm';
import qs from 'qs';

import LoginEntraForm from './LoginEntraForm';
import { history } from '../../helpers/history';
import ErrorModal from '../Base/ErrorModal';
import { NavLink } from 'react-router-dom';

class LoginPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showForm: false
        }

        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleErrorDimisss = this.handleErrorDimisss.bind(this);
    }

    componentDidMount(){
        const error = localStorage.getItem('loginError');
        if(!error){
            const queryError = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).error
            if(queryError){
                localStorage.setItem('loginError', queryError)
                history.push('/login');
            }
        }else{
            if(error){
                this.setState({ loginError: error })
                localStorage.clear('loginError')
            }
        }
    }

    handleSwitch(){
        this.setState(prevState => ({ showForm: !prevState.showForm }))
    }

    handleErrorDimisss(){
        this.setState({ loginError: null });
    }

    render(){
        return (
            <div className="login_page">
                <div className="login_page__container">
                    <div className="container">
                        <div className="col-6">
                            <img className="login_page__image" src="/images/login_page_img.png" alt="EduVault Logo"/>
                        </div>
                        <div className="col-6 login_code_container">
                            <img className="login_page__logo" src="/images/logo_full.png" alt="SIS Global Logo"/>
                            {this.state.loginError && <ErrorModal isOpen={this.state.loginError} onRequestClose={this.handleErrorDimisss} title="Invalid Share Link" description={this.state.loginError}/>}
                            <div className='qr_form'>
                                <div className='qr_form_title'>Scan the QR code with Microsoft Authenticator to retrieve and save your Verified ID</div>
                                <div className='qr_form_body'>How to Scan<br/>
                                In the app, open the verified ID tab and tap on QR code scan icon.</div>
                                {<LoginEntraForm/>}
                                <p>Don't have a credential? <NavLink to="/create">Create one now</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(LoginPage);
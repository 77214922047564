import { connect } from 'react-redux';
import React from 'react';
import '../styles/styles.scss';
import AppRouter from '../routers/AppRouter';

class App extends React.Component {
  render(){
    return (
      <div className="App">
        <AppRouter/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alerts } = state;
  return {
    alert: alerts
  }
}

export default connect(mapStateToProps)(App);

import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { getStudentDegrees } from '../../services/degrees';
import Preloader from '../Preloader';
import DegreePage from './DegreePage';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import moment from 'moment';
import AlertBox from '../AlertBox';
import { history } from '../../helpers/history';
import { MdOutlineGrading, MdOutlinePendingActions, MdPendingActions } from 'react-icons/md'

class DegreesPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            degress: []
        }
    }

    async componentDidMount(){
        try{
            if(this.props.auth && this.props.auth.agent && this.props.auth.agent.token){
                const degrees = await getStudentDegrees(this.props.auth.agent.token);
                
                if(degrees && degrees.length === 1){
                    history.push('/degrees/' + degrees[0].id)
                }

                this.setState({ degrees, loading: false })
            }
        }
        catch(e){
            this.setState({ loading: false, error: e })
        }
    }

    render(){
        return this.state.loading ? <Preloader fitToContent={true}/> : (!this.state.error ? (
            <div className='degrees'>
                <div className='degrees_page_container'>
                    <h1>Select your degree</h1>
                    <p className='degrees_page_container__writeup'>You have multiple degrees at <b>{this.props.auth.university.name}</b>, please select which one you would like to view</p>
                    <div className='degree_list'>
                        {this.state.degrees.map(degree => (
                            <NavLink className='degree_list__item' to={'/degrees/' + degree.id}>
                                <div className='degree_list__item__details__prgoress_icon'>
                                    {degree.date_obtained ? <div className='degree_list_item__icon completed'><MdOutlineGrading/></div> : <div className='degree_list_item__icon in_progres'><MdPendingActions/></div>}
                                </div>
                                <div className='degree_list__item__details'>
                                    <div className='degree_list__item__title text-truncate'>{degree.degree_name}</div>
                                    <div className='timestamp'>{degree.date_obtained ? `Obtained in: ${moment(degree.date_obtained).format('y')}` : 'Not yet achieved' }</div>
                                </div>
                                <div>
                                    {degree.date_obtained ? <div className='degree_list_item__badge completed'>Completed</div> : <div className='degree_list_item__badge in_progress'>In Progess</div>}
                                </div>
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>
        ): <AlertBox type="alert alert-danger">{this.state.error}</AlertBox>);
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(DegreesPage);
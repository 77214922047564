import { handleResponse } from './misc';

const getUniversity = (authToken, id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/university/' + id, requestOptions).then(handleResponse).then((university) => {
        return university;
    });
}

export { getUniversity }
import { HiDownload, HiOutlineX } from 'react-icons/hi';
import Modal from 'react-modal';
import TranscriptsPage from './TranscriptsPage';
const TranscriptsModal = (props) => {
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="transcripts_modal" onRequestClose={props.onRequestClose}>
            <TranscriptsPage degree={props.degree}/>
            <div className="modal__actions">
                <a download={`${props.student.first_name} ${props.student.last_name} - ${props.degree.degree_name} Transcript.pdf`} className="btn" href={props.degree.transcript_watermark}><HiDownload/>Save as PDF</a>
            </div>
            <div className='modal_close' onClick={props.onRequestClose}><HiOutlineX/></div>
        </Modal>
    );
};

export default TranscriptsModal;
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { HiOutlineX } from 'react-icons/hi';
import { shareDegree } from '../../services/degrees';
import LoadingModal from '../Base/LoadingModal';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import AlertBox from '../AlertBox';
import Toggle from 'react-toggle';
import "react-toggle/style.css" // for ES6 modules
import ConfirmModal from '../Base/ConfirmModal';
import moment from 'moment';
import { toDateOnly } from '../../services/dates';
import Select from 'react-select';

class ShareModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            generatingToken: false,
            include_id_number: false,
            include_contact_info: true,
            include_transcripts: false,
            error: null,
            confirmShare: false,
            expiryDate: moment().add(3, 'days'),
            selectedDegrees: this.props.degree ? [this.props.degree] : []
        }
        
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleIncludeIdNumberChange = this.handleIncludeIdNumberChange.bind(this);
        this.handleIncludeContactInfoChange = this.handleIncludeContactInfoChange.bind(this);
        this.handleIncludeTranscriptsChange = this.handleIncludeTranscriptsChange.bind(this);
        this.handleShareConfirm = this.handleShareConfirm.bind(this);
        this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
        this.handleDegreeChange = this.handleDegreeChange.bind(this);
    }

    handleFieldChange(e){
        this.setState({[e.target.name]: e.target.value })
    }

    handleShareConfirm(e){
        e.preventDefault();
        this.setState(prevState => ({ confirmShare: !prevState.confirmShare }));
    }

    async handleFormSubmit(){
        if(!(this.state.contact_person_name && this.state.contact_person_email && this.state.company && this.state.selectedDegrees.length > 0)){
            this.setState({ error: 'Please fill in all required fields' });
        }else{this.setState({ generatingToken: true, confirmShare: false })
            try{
                const shareLinkResponse = await shareDegree(this.props.auth.agent.token, this.state.selectedDegrees.map(degree => degree.id), this.state.contact_person_name, this.state.contact_person_email, this.state.company, this.state.include_id_number, this.state.include_contact_info, this.state.include_transcripts, this.state.expiryDate.format('ddd MMM DD YYYY hh:mm:ss [GMT]ZZ'));
                await this.props.refreshShares();
                this.setState({ generatingToken: false, shareLink: shareLinkResponse.share_link });
            }catch(e){
                this.setState({ generatingToken: false, error: e });
            }
        }
    }

    handleExpiryDateChange(date){
        this.setState({ expiryDate: date })
    }

    handleIncludeIdNumberChange(){
        this.setState(prevState => ({ include_id_number: !prevState.include_id_number }))
    }

    handleIncludeContactInfoChange(){
        this.setState(prevState => ({ include_contact_info: !prevState.include_contact_info }))
    }

    handleIncludeTranscriptsChange(){
        this.setState(prevState => ({ include_transcripts: !prevState.include_transcripts }))
    }

    handleDegreeChange(e){
        this.setState(({ selectedDegrees: e.map(element => element.value) }))
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal share_modal" onRequestClose={this.props.onRequestClose}>
                {this.state.shareLink ? (
                    <div className='registration_page__step complete'>
                        <div class="success-checkmark">
                            <div class="check-icon">
                                <span class="icon-line line-tip"></span>
                                <span class="icon-line line-long"></span>
                                <div class="icon-circle"></div>
                                <div class="icon-fix"></div>
                            </div>
                        </div>
                        <div className='modal_title'>Degree Shared</div>
                        <p>You have successfully shared your <b>{this.props.degree.degree_name}</b> degree with <b>{this.state.contact_person_name} ({this.state.company})</b></p>
                        <div className='modal_close' onClick={this.props.onRequestClose}><HiOutlineX/></div>
                    </div>
                ) : (
                    <div>
                    <div className='modal_title'>Share With Employer</div>
                        <p>Securely share your <b>{this.props.degree.name}</b> with an employer. Your employer will recieve a share link with a pin code to their email. Please ensure the details entered below are accurate.</p>
                        <form method='POST' onSubmit={this.handleShareConfirm}>
                            <div className='form-group'>
                                <label>Employer Company</label>
                                <input required={true} type="text" name='company' value={this.state.company} placeholder="Enter employer company" onChange={this.handleFieldChange}/>
                            </div>
                            <div className='form-group'>
                                <label>Contact Person Name</label>
                                <input required={true} type="text" name='contact_person_name' value={this.state.contact_person_name} placeholder="Enter contact person name" onChange={this.handleFieldChange}/>
                            </div>
                            <div className='form-group'>
                                <label>Degrees to share</label>
                                <Select className="form_select" onChange={this.handleDegreeChange} value={this.state.selectedDegrees.map(degree => ({ value: degree, label: degree.degree_name }))} isMulti options={this.props.degrees.map(degree => ({ value: degree, label: degree.degree_name }))}/>
                            </div>
                            <div className='form-group'>
                                <label>Contact Person Email Address</label>
                                <input required={true} type="email" placeholder="Enter contact person email address" name='contact_person_email' value={this.state.contact_person_email} onChange={this.handleFieldChange}/>
                                {(this.state.contact_person_email && this.state.contact_person_email.length > 0) ? <p>We will email a temporary link and pin to <b>{this.state.contact_person_email}</b></p>: <p>Please ensure this is accurate as this is where we will email the code.</p>}
                            </div>
                            <div className='form-group'>
                                <label>Valid Until</label>
                                <SingleDatePicker 
                                    date={this.state.expiryDate}
                                    onDateChange={this.handleExpiryDateChange} 
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    numberOfMonths={1}
                                    firstDayOfWeek={1}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Include contact info</label>
                                <Toggle checked={this.state.include_contact_info} onChange={this.handleIncludeContactInfoChange}/>
                            </div>
                            <div className='form-group'>
                                <label>Include ID number</label>
                                <Toggle checked={this.state.include_id_number} onChange={this.handleIncludeIdNumberChange}/>
                            </div>
                            <div className='form-group'>
                                <label>Include academic transcripts</label>
                                <Toggle checked={this.state.include_transcripts} onChange={this.handleIncludeTranscriptsChange}/>
                            </div>
                            <div className="modal__actions">
                                <button type='submit' disabled={this.state.selectedDegrees.length === 0} className="btn">Share with employer</button>
                            </div>
                            {this.state.error && <AlertBox type="alert alert-danger mt-medium">{this.state.error}</AlertBox>}
                        </form>
                        <div className='modal_close' onClick={this.props.onRequestClose}><HiOutlineX/></div>
                        {this.state.generatingToken && <LoadingModal isOpen={this.state.generatingToken} text={<p>Sharing <b>{this.props.degree.degree_name}</b> with <b>{this.state.contact_person_email}</b></p>}/>}
                        {this.state.confirmShare && <ConfirmModal isOpen={this.state.confirmShare} title="Confirm Degree Share" onConfirm={this.handleFormSubmit} onRequestClose={this.handleShareConfirm} description={(
                            <div>
                                <p>You have about to share your <b>{this.props.degree.degree_name}</b> degree. Please confirm the details shown below:</p>
                                <table class='share_details'>
                                    <thead>
                                        <tr><th colSpan={2}>Share details</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Company</th>
                                            <td>{this.state.company}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Person Name</th>
                                            <td>{this.state.contact_person_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Email</th>
                                            <td>{this.state.contact_person_email}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Info Included</th>
                                            <td>{this.state.include_contact_info ? 'Yes' : 'No'}</td>
                                        </tr>
                                        <tr>
                                            <th>ID Included</th>
                                            <td>{this.state.include_id_number ? 'Yes' : 'No'}</td>
                                        </tr>
                                        <tr>
                                            <th>Transcripts Included</th>
                                            <td>{this.state.include_transcripts ? 'Yes' : 'No'}</td>
                                        </tr>
                                        <tr>
                                            <th>Valid Until</th>
                                            <td>{toDateOnly(this.state.expiryDate)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}/>}
                    </div>
                )}    
            </Modal>)
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ShareModal);
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { studentActions } from '../actions/students';
import OrganizationDashboard from '../components/OrganizationPortal/OrganizationDashboard';
import Preloader from '../components/Preloader';
import { studentService } from '../services/students';

export const OrganizationRoute = ({ dispatch, isAuthenticated, component: Component, loggedIn, auth, allowedRoles, ...rest }) => {
    let componentToRoute = undefined;

    if(!localStorage.getItem('user')){
        componentToRoute = <Redirect to="/login" />;
    }    

    if(auth.loading){ 
        componentToRoute = <Preloader fitToContent={true}/>
        if(auth.agent){
            studentService.getStudent(auth.agent.token).then(student => {
                dispatch(studentActions.refreshStudentSession({
                    ...student,
                    token: auth.agent.token
                }))
            }).catch(e => {
                // Invalid student or token
                dispatch(studentActions.logout());
            })
        }
    }

    return (
        <Route {...rest} component={(props) => (
            <div>
                {componentToRoute ? componentToRoute : <OrganizationDashboard component={<Component {...props}/>}/>}
            </div>
        )}/>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid,
    auth: state.auth ? state.auth : undefined,
    agent: state.auth.agent ? state.auth.agent.agent : undefined
})

export default connect(mapStateToProps)(OrganizationRoute)
import Modal from 'react-modal';
import { TbFaceIdError } from 'react-icons/tb';

const ErrorModal = (props) => {
    const confirmText = props.confirmText || 'Okay';
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal error_modal" onRequestClose={props.onRequestClose}>
            <div className='error_modal_icon'><TbFaceIdError/></div>
            <div className="modal__title">{props.title}</div>
            <div className="modal__content">{props.description}</div>
            <div className="modal__actions">
                <button className="btn cancel" onClick={props.onRequestClose}>{confirmText}</button>
            </div>
        </Modal>
    );
};

export default ErrorModal;
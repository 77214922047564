import { handleResponse } from './misc';

const getUserProfile = async (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/ad/profile', requestOptions).then(handleResponse).then((user) => {
        return user;
    })
}

const getStudentFromAd = async (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/ad/student', requestOptions).then(handleResponse).then((student) => {
        return student;
    });
}

const getAdUrl = async () => {
    const requestOptions = {
        method: 'GET'
    };

    return fetch('/api/ad/signin', requestOptions).then(handleResponse).then(({ url }) => {
        return url;
    })
}

export {
    getUserProfile,
    getStudentFromAd,
    getAdUrl
}
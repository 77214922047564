import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

class MenuItem extends React.Component{
    render(){
        return (
            <div className="dashboard_menu__item">
                <NavLink className="dashboard_menu__item__navlink" onClick={this.props.onClick} activeClassName="active" to={this.props.to}>{this.props.children}{this.props.notificationCount !== undefined && <div className="count_badge">{this.props.notificationCount}</div>}{this.props.label && <div className="dashboard_menu__item__label"><span>{this.props.label}</span></div>}</NavLink>
            </div>
        );
    }
}

const mapStateToProps = (state) => (state);

export default connect(mapStateToProps)(MenuItem);
import Modal from 'react-modal';
const ConfirmModal = (props) => {
    const confirmText = props.confirmText || 'Confirm';
    const cancelText = props.cancelText || 'Cancel';
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal modal__preloader" onRequestClose={props.onRequestClose}>
            <div className="modal__title">{props.title}</div>
            <div className="modal__content">{props.description}</div>
            <div className="modal__actions">
                <button className="btn" onClick={props.onConfirm}>{confirmText}</button>
                <button className="btn cancel" onClick={props.onRequestClose}>{cancelText}</button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
import React from 'react';
import { connect } from 'react-redux';
import Menu from '../Menu/Menu';

class OrganizationDashboard extends React.Component{
    constructor(props){
        super(props);

        this.statusTimer = undefined;
        this.state = {
            mobileMenuOpen: false
        };
        
        this.mobileMenuTrigger = this.mobileMenuTrigger.bind(this);
    }

    mobileMenuTrigger(){
        this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
    }

    render(){
        return (
            <div className="dashboard">
                <Menu/>
                <div className='dashboard_container'>
                    <div className="dashboard_content">
                        <div className='dashboard_component'>{this.props.component}</div>
                    </div>
                </div>
            </div>
        ); 
    };
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(OrganizationDashboard);
import React from "react";
import { connect } from 'react-redux';
import { getUniversity } from "../../services/university";
import Preloader from "../Preloader";
import VaultList from "./VaultList";

class OrganizationLanding extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingOrganization: true,
            organization: null
        }
    }

    async componentDidMount(){
        const organization = await getUniversity(this.props.auth.agent.token, this.props.match.params.id);
        this.setState({
            organization,
            loadingOrganization: false
        })
    }

    render(){
        return !this.state.loadingOrganization ? (
            <div className="landing_page">
                <div className="landing_page__container">
                    <div className="landing_page_header">
                        <div className="landing_page_details">
                            <h2 className="subtitle">{this.state.organization.name}</h2>
                            <h1 className="title-thin">Welcome, Dain</h1>
                        </div>
                        <div className="landing_page_header_logo">
                            <img src='/images/logo_full.png' alt="EduVault Logo"/>
                            Admin
                        </div>
                    </div>
                    <VaultList/>
                </div>
            </div>
        ) : <Preloader/>;
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(OrganizationLanding);
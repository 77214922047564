import React from 'react';
import { connect } from 'react-redux';
import { createShareVerifyAndLinkPresentaionRequest } from '../../services/entra'
import socket from '../../services/socket';
import Preloader from '../Preloader';
import { studentActions } from '../../actions/students'
import AlertBox from '../AlertBox';

class EmployerVerifyAndLink extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            loadingPresentation: true,
            presentationRequestError: null,
            callbackInfo: null,
            awaitingCallback: false
        }
    }

    async componentDidMount(){
        socket.on('presentationRequestRetrieved', ({ state }) => {
            if(this.state.presentationRequest && this.state.presentationRequest.state === state){
                this.setState({ awaitingCallback: true, error: null });
            }
        });

        socket.on('verifyAndLinkFail', async (body) => {
            if(this.state.presentationRequest && this.state.presentationRequest.state === body.state){
                this.setState({ error: body.error });
            }
        });

        socket.on('verifyAndLinkSuccess', async (body) => {
            if(this.state.presentationRequest && this.state.presentationRequest.state === body.state){
                const subject = body;
                await this.props.onVerified(subject);
                this.setState({ error: null })
                const { dispatch } = this.props;
                dispatch(studentActions.loginStudentWithPayload(body));
            }
        });

        try{
            const presentationRequest = await createShareVerifyAndLinkPresentaionRequest();
            this.setState({
                presentationRequest,
                presentationRequestError: null,
                loadingPresentation: false
            });
        }
        catch(e){
            this.setState({ presentationRequestError: e })
        }
    }

    componentWillUnmount(){
        socket.off('presentationRequestRetrieved');
        socket.off('verifyAndLinkFail');
        socket.off('verifyAndLinkSuccess');
    }

    render(){
        return !this.state.loadingPresentation ? (!this.state.awaitingCallback ? (
            <div className='qr_form'>
                <img className='login_code' src={this.state.presentationRequest.qrCode}/>
            </div>
        ) : (
            <div className='waiting_for_auth'>
                {this.state.error && <AlertBox type="alert alert-danger">{this.state.error}</AlertBox>}
                <Preloader/>
                <div className='waiting_for_auth__label'>Please authenticate the request using your authenticator app.</div>
            </div>
        )) : (
            <Preloader/>
        );
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(EmployerVerifyAndLink);


import moment from 'moment';
import { handleResponse } from './misc';

const getDegreeCourses = (authToken, degreeId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/degrees/' + degreeId + '/courses', requestOptions).then(handleResponse).then((courses) => {
        return courses;
    });
}

const getCourseYearBreakdown = (courses) => {
    const years = [];
    const yearBreakDown = [];
    courses.forEach(course => {
        const courseYear = moment(course.course_date).year();
        if(!years.includes(courseYear)){ years.push(courseYear) }
    });

    years.forEach(year => {
        const courseResults = courses.filter(course => moment(course.course_date).year() === year);
        const yearBreakDownItem = {
            year,
            courses: courseResults
        };

        yearBreakDown.push(yearBreakDownItem);
    });

    return yearBreakDown;
};

export { getDegreeCourses, getCourseYearBreakdown }
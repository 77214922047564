import React from 'react';
import { HiOutlinePencil } from 'react-icons/hi';
import { connect } from 'react-redux';
import EditStudentInfoModal from './EditStudentInfoModal';

class StudentInfoComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fieldsEditable: false,
            editingInfo: false
        }

        this.evaluteAllowedFields = this.evaluteAllowedFields.bind(this);
        this.handleEditInfoTrigger = this.handleEditInfoTrigger.bind(this);
    }
    
    componentDidMount(){
        this.evaluteAllowedFields();
    }

    evaluteAllowedFields(){
        const displayEmail = (!this.props.auth.agent.share || (this.props.auth.agent.share && this.props.auth.agent.share.include_contact_info !== false));
        const displayMobile = (!this.props.auth.agent.share || (this.props.auth.agent.share && this.props.auth.agent.share.include_contact_info !== false));
        const displayIdNumber = (!this.props.auth.agent.share || (this.props.auth.agent.share && this.props.auth.agent.share.include_id !== false));
        const fieldsEditable = !this.props.auth.agent.share;

        let hasStudentInformation = true;
        // Handle no student information
        if(!(displayEmail || displayMobile || displayIdNumber)){ hasStudentInformation = false; }

        this.setState({ displayEmail, displayMobile, displayIdNumber, hasStudentInformation, fieldsEditable });
    }

    handleEditInfoTrigger(){
        this.setState(prevState => ({ editingInfo: !prevState.editingInfo }));
    }

    render(){
        return (
            <div>
                {this.state.hasStudentInformation && (
                    <div className='degree_student_info_heading'>
                        Student Information
                        {this.state.fieldsEditable && <div className='edit_contact_info_trigger' onClick={this.handleEditInfoTrigger}>Edit Contact Info<HiOutlinePencil/></div>}
                    </div>
                )}
                {this.state.displayEmail && <div className='degree_student_info_item'>
                    <div className='degree_student_info_item__label'>Email</div>
                    <div className='degree_student_info_item__value'>{this.props.auth.agent.email}</div>
                </div>}
                {this.state.displayMobile && <div className='degree_student_info_item'>
                    <div className='degree_student_info_item__label'>Mobile</div>
                    <div className='degree_student_info_item__value'>{this.props.auth.agent.mobile_number}</div>
                </div>}
                {this.state.displayIdNumber && <div className='degree_student_info_item'>
                    <div className='degree_student_info_item__label'>ID Number</div>
                    <div className='degree_student_info_item__value'>{this.props.auth.agent.id_number}</div>
                </div>}
                {this.state.editingInfo && <EditStudentInfoModal isOpen={this.state.editingInfo} onRequestClose={this.handleEditInfoTrigger} fields={[{
                    label: 'Email',
                    name: 'email',
                    value: this.props.auth.agent.email,
                    maxLength: 100,
                    type: 'email'
                }, {
                    label: 'Mobile',
                    name: 'mobile_number',
                    value: this.props.auth.agent.mobile_number,
                    maxLength: 25,
                    type: 'phone_number'
                }]}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(StudentInfoComponent);
let agent = JSON.parse(localStorage.getItem('user'));
let university = JSON.parse(localStorage.getItem('university'));

if(agent){
    localStorage.setItem('user', JSON.stringify(agent));
}

if(university){
    localStorage.setItem('university', JSON.stringify(university))
}

const initialState = agent ? { loggedIn: true, agent, university, loading: true } : {};

export default function authentication(state = initialState, action){
    switch(action.type){
        case 'AGENT_LOGIN_REQUEST':
            return {
                loggingIn: true,
                agent: action.agent
            }
        case 'AGENT_LOGIN_SUCCESS':
            return {
                loggedIn: true,
                agent: action.agent,
                university: action.university
            }
        case 'SET_CURRENT_AGENT_SET':
        case 'AVATAR_SET':
            state.agent = {
                ...state.agent,
                ...action.agent
            }
            
            return state;
        case 'AGENT_LOGOUT':
        case 'AGENT_LOGIN_FAILURE':
            return { }
        default: 
            return state;
    }
}